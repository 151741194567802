import httpService from "./httpService";

const apiService = {
  // logIn
  logIn(data) {
    return httpService.post("login", data);
  },

  //   getSideBarManuClaims
  getSideBarManuClaims() {
    return httpService.get("get-workflows");
  },

  //add workflow state
  addworkflowState() {
    return httpService.post("add-workflowstate");
  },

  // getAvailableNextStep
  getAvailableNextStep(currentstate, workflowid, currentstatus) {
    return httpService.post(
      `getavailablenextsteps/${currentstate}/${workflowid}/${currentstatus}`
    );
  },

  // getMemberClaimsByGuidId
  getMemberClaimsByGuidId(guidid) {
    return httpService.get(`get-member-claims-byguidid/${guidid}`);
  },

  getNextSteps(currentstate, nextstate) {
    return httpService.get(
      `get-nextstep-bystates/${currentstate}/${nextstate}`
    );
  },

  // submitnextsteps
  // submitClaimToLegalClaim
  submitClaimToLegalClaim(data = {}) {
    return httpService.post(`submitnextsteps`, data);
  },

  // getWorkFlowStates
  getWorkFlowStates(id) {
    return httpService.get(`get-workflowstates/${id}`);
  },

  // getWorkFlowStatesByUser
  getWorkFlowStatesByUser(workflowId, user) {
    return httpService.get(`get-workflowstatesbyuser/${workflowId}/${user}`);
  },

  //   FAO
  // addMember
  addMember(data = {}) {
    return httpService.post(`add-memberdetails`, data);
  },

  // updateClaim
  updateClaim(data = {}) {
    return httpService.post(`update-memberclaim`, data);
  },

  // getClaims
  getClaims() {
    return httpService.get("get-membersclaims");
  },

  // getClaimBymembershipNo
  getClaimByMembershipNo(membershipNo) {
    return httpService.get(`get-memberclaims-bymembershipno/${membershipNo}`);
  },

  // Get Member details
  getMemberDetails(membershipNumber) {
    return httpService.get(`get-memberdetails/${membershipNumber}`);
  },

  // Add New Claim
  addNewClaim(data = {}) {
    return httpService.post(`add-memberclaim`, data);
  },

  // Add Required Documents
  addDocumentRequired(data = {}) {
    return httpService.post(`add-requireddocument`, data);
  },

  // getClaimByMemberId
  getClaimByMemberId(memberClaimId) {
    return httpService.get(`get-memberclaims/${memberClaimId}`);
  },

  // getClaimsByState
  getClaimsByState(claimState, workFlowId) {
    return httpService.get(
      `get-membersclaims-bystate/${claimState}/${workFlowId}`
    );
  },

  // email
  //   sendEmail
  sendEmail(data = {}) {
    return httpService.post(`send-email`, data);
  },

  // getCommunicationByMemberId
  getCommunicationByMemberId(memberClaimId) {
    return httpService.get(`get-communicationmessages/${memberClaimId}`);
  },

  // note
  // getNoteByMemberId
  getNoteByMemberId(memberClaimId) {
    return httpService.get(`get-notes/${memberClaimId}`);
  },

  // addWorkflowState
  addWorkflowState(data = {}) {
    return httpService.post(`add-workflowstate`, data);
  },

  // addWorkflowComponent
  addWorkflowComponent(data = {}) {
    return httpService.post(`add-workflowcomponent`, data);
  },

  // getWorkFlowStatuses
  getWorkFlowStatuses(workFlowId) {
    return httpService.get(`get-workflowstatuses/${workFlowId}`);
  },

  // addWorkflow
  addWorkflow(data = {}) {
    return httpService.post(`add-workflow`, data);
  },

  // addDocument
  addDocument(data = []) {
    return httpService.post(`add-document`, data);
  },

  // getDocumentByMemberId
  getDocumentByMemberId(memberClaimId) {
    return httpService.post(`get-document/${memberClaimId}`);
  },

  deleteDocument(documentId) {
    console.log("documentId???");
    console.log(documentId);
    return httpService.delete(`delete-document/${documentId}`);
  },

  // addWorkflowComponentAPI
  addWorkflowComponentAPI(data = []) {
    return httpService.post(`add-workflowcomponent`, data);
  },

  // getMember
  getMember() {
    return httpService.get(`get-membersclaims`);
  },

  // addNote
  addNote(data = []) {
    return httpService.post(`add-note`, data);
  },

  // getUsersbyrole
  getUsersbyrole(role) {
    return httpService.get(`get-usersbyrole/${role}`);
  },

  // getUserPermissions
  getUserPermissions() {
    return httpService.post(`user-permissions`);
  },

  // addBulkMembers
  addBulkMembers(data = {}) {
    return httpService.post(`upload-bulkmembers`, data);
  },

  // getBulkMemberDetails
  getBulkMemberDetails(membershipNo) {
    return httpService.get(`get-memberdetails/${membershipNo}`);
  },

  // getWorkflowComponent
  getWorkflowComponent(workFlowId, worKFlowStateId) {
    return httpService.post(
      `get-workflowcomponent/${workFlowId}/${worKFlowStateId}`
    );
  },

  getWorkflows() {
    return httpService.get(`get-workflows`);
  },

  getDocumentRequired(workflowid, workflowstateid) {
    return httpService.get(
      `get-requireddocument/${workflowid}/${workflowstateid}`
    );
  },

  getDocuments(MemberClaimId) {
    return httpService.get(`get-document/${MemberClaimId}`);
  },

  addAuditEvent(data = {}) {
    return httpService.post(`add-auditevent`, data);
  },
  getWorkflowStatuses(id) {
    return httpService.get(`get-workflowstatuses/${id}`);
  },
  addWorkflowSteps(data = {}) {
    return httpService.post(`add-workflowsteps`, data);
  },
  getUserRoles(data = {}) {
    return httpService.get(`get-getroles`, data);
  },
  createUser(data = {}) {
    return httpService.post(`add-createuser`, data);
  },
  updateMember(data = {}) {
    return httpService.post(`update-memberdetails`, data);
  },
  updateMemberClaim(data = {}) {
    return httpService.post(`update-memberclaim`, data);
  },
  // getWorkflowComponentsByid(workflowid, worflowstateid) {
  //   return httpService.post(
  //     `get-workflowcomponent/${workflowid}/${worflowstateid}`
  //   );
  // },

  // addRecipients
  addRecipients(data = {}) {
    return httpService.post(`add-requiredrecipient`, data);
  },

  // getRequiredRecipients
  getRequiredRecipients(workflowId, workflowStateId) {
    return httpService.get(
      `get-requiredrecipient/${workflowId}/${workflowStateId}`
    );
  },

  // getReqGeneratedDocRecipients
  getReqGeneratedDocRecipients(workflowId, workflowStateId) {
    return httpService.get(
      `/get-requiredGeneratedDocRecipients/${workflowId}/${workflowStateId}`
    );
  },

  addReport(data = {}) {
    return httpService.post(`add-report`, data);
  },
  getReports(data = {}) {
    return httpService.get(`get-reports`, data);
  },
  runReport(reportid, startdate, enddate) {
    return httpService.get(`get-runreport/${reportid}/${startdate}/${enddate}`);
  },
  editReport(data = {}) {
    return httpService.post(`update-report`, data);
  },
  updateWorkflowState(data = {}) {
    return httpService.put(`update-workflowstate`, data);
  },
  fetchCommunicationByCommunicationId(id) {
    return httpService.get(`get-communicationmessages-byCommunicationId/${id}`);
  },
  // fetchAvailableNextSteps(currentState, workflowId) {
  //   return httpService.post(
  //     `getavailablenextsteps/${currentState}/${workflowId}`
  //   );
  // },
  sendSMS(data = {}) {
    return httpService.post(`SendSMS`, data);
  },
  fetchUsers(data = {}) {
    return httpService.get(`get-all-users`, data);
  },
  addClaimType(data = {}) {
    return httpService.post(`claimtype`, data);
  },
  fetchClaimTypes(workflowId) {
    return httpService.get(`claimtypes-byworkflowId/${workflowId}`);
  },
  createRole(data = {}) {
    return httpService.post(`roles`, data);
  },
  createPermission(data = {}) {
    return httpService.post(`permission`, data);
  },
  createPermissionRole(data = {}) {
    return httpService.post(`permission-role`, data);
  },
  sendGenerateddocEmail(data = {}) {
    return httpService.post(`send-generateddoc-email`, data);
  },
  fetchAllClaimsByMembershipNo(membershipnumber) {
    return httpService.get(
      `get-memberclaims-list-bymembershipno/${membershipnumber}`
    );
  },
  addGeneratedDocRecipient(data = {}) {
    return httpService.post(`add-requiredGeneratedDocRecipients`, data);
  },
  getRequiredRecipientsForGenDoc(workflowid, workflowstateid) {
    return httpService.get(
      `get-requiredGeneratedDocRecipients/${workflowid}/${workflowstateid}`
    );
  },
  sendGenerateddocSMS(data = {}) {
    return httpService.post(`send-generateddoc-sms`, data);
  },
  addOperationalNote(data = {}) {
    return httpService.post(`add-communication-note`, data);
  },
  fetchOperationalNotesByMemeberClaimId(memberClaimId) {
    return httpService.get(`get-communication-notes/${memberClaimId}`);
  },
  fetchPregeneratedDoc(memberClaimId, recipient) {
    return httpService.get(
      `get-pregenerated-doc/${memberClaimId}/${recipient}`
    );
  },
  fetchPregeneratedDocs(memberClaimId) {
    return httpService.get(`get-generated-doc/${memberClaimId}`);
  },
  getDocumentByDocumentId(documentId) {
    return httpService.get(`get-document-byId/${documentId}`);
  },
  getPregeneratedDocumentByDocumentId(documentId) {
    return httpService.get(`get-generated-doc-byid/${documentId}`);
  },
};

export default apiService;
